import { useEffect } from "react"
import MentionLegales from "../component/MentionLegales";
import Header from "../component/Header";
import Footer from "../component/Footer";
export default function Mention({sendPageView}){
    useEffect(() => {
        sendPageView("/mentions", "Mentions légales");
    }); 

    return (
        <>
        <Header/>
        <div className="w-5/6 mx-auto mt-16 mb-12 h-screen">
            <MentionLegales/>
        </div>
        <Footer/>
        </>
    )
}