export default function MentionLegales() {
    return (
        <div className="container mx-auto py-8 px-4">
            <h3 className="text-5xl font-bold titre">Mentions légales</h3>

            <h4 className="text-3xl font-bold mt-10 titre">Crédits</h4>
            <ul>
                <li>Site web réalisé par : <a href="mailto:adrien.tree@gmail.com">Adrien SCHER</a></li>
                <li>Hébergement : OVH</li>
            </ul>

            <h4 className="text-3xl font-bold mt-10 titre">Protection des droits d'auteur et de la propriété intellectuelle</h4>
            <p>Tous les contenus présents sur ce site (images, vidéos et tout autre élément), sont la propriété exclusive de Camille Untereiner et sont protégés par les lois françaises et internationales relatives aux droits d'auteur et à la propriété intellectuelle. Toute reproduction, distribution, modification ou utilisation de ces contenus sans l'autorisation préalable écrite de Camille Untereiner est strictement interdite.</p>

            <h4 className="text-3xl font-bold mt-10 titre">Cookies</h4>
            <p>Ce site utilise des cookies pour améliorer l'expérience utilisateur. En continuant à naviguer sur ce site, vous acceptez l'utilisation de cookies.</p>

           </div>
    );
}
