import React, { useEffect, useState } from "react";
import { getColor } from "../tools/getColor";
import { getProjetHref } from "../tools/getProjetHref";

export default function DescriptionProjet(props) {
    const { projet, gauche } = props;
    const [isHovered, setIsHovered] = useState(false);
    const [colorClass, setColorClass] = useState('');

    useEffect(() => {        
        setColorClass(getColor(projet.id));
    }, [projet]);

    return (
        <a
            href={getProjetHref(projet.id)}
            className={`relative w-full h-96 md:h-[87vh] flex items-center justify-center ${colorClass} mt-4 overflow-hidden`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >   
                <div className={`absolute fixed inset-0 flex flex-col items-center justify-center text-light p-2 w-full ${!isHovered &&  'opacity-0'}`}>
                    <h2 className="text-3xl font-semibold text-center titre">{projet.titre}</h2>
                    {projet.titre2 && <h2 className="text-lg font-semibold text-center titre">{projet.titre2}</h2>}
                    <p className="text-justify text-sm mt-2 mx-24">{projet.description}</p>
                </div>
                <img
                className={`w-full h-full object-cover transition-all duration-500 ease-in-out opacity-transition opacity-100 ${isHovered &&  'opacity-15'}`}
                src={projet.imagePrincipale.img}
                alt={projet.imagePrincipale.alt}
            />
            
        </a>
    );
}
