import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import MainPage from "./MainPage";
import Apropos from "./Apropos";
import Mention from "./Mention";

export default function Router() {
  const [accepted, setAccepted] = useState(true);

  useEffect(() => {
    const hasAccepted = localStorage.getItem("cookiesAccepted");
    if (hasAccepted && hasAccepted === "true") {
      setAccepted(true);
    }else{
      setAccepted(false);
    }
  }, []);

  useEffect(() => {
    if (accepted) {
      ReactGA.initialize("G-1086Q2MY6Y");
    }
  }, [accepted]);

  const handleAccept = () => {
    localStorage.setItem("cookiesAccepted", true);
    setAccepted(true);
  };
  const sendPageView = (page, title) => {
    if (accepted) {
      ReactGA.send({ hitType: "pageview", page, title });
    }
  };
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <MainPage
                sendPageView={sendPageView}
                accepted={accepted}
                handleAccept={handleAccept}
              />
            }
          ></Route>
          <Route
            path="/apropos"
            element={<Apropos sendPageView={sendPageView} />}
          ></Route>
          <Route
            path="/mentions"
            element={<Mention sendPageView={sendPageView} />}
          ></Route>
          <Route
            path="/projet/:id"
            element={
              <MainPage
                sendPageView={sendPageView}
                accepted={accepted}
                handleAccept={handleAccept}
              />
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
