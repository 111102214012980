export const getColor = (idProjet) => {
        switch (idProjet) {
            case 'fables':
                return `bg-fables`; 
            case 'plume':
                return 'bg-plume'; 
            case 'oecophylla':
                return 'bg-oecophylla'; 
            case 'racines':
                return 'bg-racines'; 
            default:
                return 'bg-fables'; 
        }
    }

    export const getTextColor = (idProjet) => {
        switch (idProjet) {
            case 'fables':
                return `text-fables`; 
            case 'plume':
                return 'text-plume'; 
            case 'oecophylla':
                return 'text-oecophylla'; 
            case 'racines':
                return 'text-racines'; 
            default:
                return 'text-fables'; 
        }
    }