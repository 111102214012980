import { useState } from "react";
import { useSwipeable } from 'react-swipeable';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function Gallery(props) {
    const { images } = props;
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    const openImage = (index) => {
        setSelectedImageIndex(index);
        document.body.style.overflow = 'hidden';
    };

    const closeImage = () => {
        setSelectedImageIndex(null);
        document.body.style.overflow = 'auto';
    };

    const prevImage = () => {
        setSelectedImageIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const nextImage = () => {
        setSelectedImageIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };


    const handlers = useSwipeable({
        onSwipedLeft: nextImage,
        onSwipedRight: prevImage,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <div {...handlers}>
            <div>
                <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4`}>
                    {images.map((image, index) => (
                        <div key={index}>
                            <div className="relative">
                                <img
                                    className="w-full h-80 object-cover cursor-pointer"
                                    src={image.img}
                                    alt={image.alt}
                                    onClick={() => openImage(index)}
                                />
                            </div>
                        </div>
                    ))}
                </div>

                {selectedImageIndex !== null && (
                    <div className="fixed inset-0 flex flex-col items-center justify-around bg-black bg-opacity-95 z-50">
                        <div className="h-5/6 relative">
                            {images.map((image, index) => (
                                <img
                                    key={index}
                                    src={image.img}
                                    alt={`Slide ${index}`}
                                    className={`fixed  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inset-0 max-h-80vh max-w-full object-cover transition-opacity duration-500 ${index === selectedImageIndex ? 'opacity-100' : 'opacity-0'
                                        }`}
                                />
                            ))}
                        </div>
                        <div className="flex">
                            {Array.from({ length: 5 }).map((_, index) => {
                                let previewIndex = selectedImageIndex - 2 + index;
                                if (previewIndex < 0) {
                                    previewIndex = images.length + previewIndex;
                                } else if (previewIndex >= images.length) {
                                    previewIndex = previewIndex - images.length;
                                }
                                return (
                                    <img
                                        key={index}
                                        src={images[previewIndex].img}
                                        alt={`Slide ${previewIndex}`}
                                        className={`h-12 w-12 object-cover rounded ${selectedImageIndex === previewIndex ? 'opacity-100' : 'opacity-20'}`}
                                        onClick={() => setSelectedImageIndex(previewIndex)}
                                    />
                                );
                            })}
                        </div>


                        <button className="absolute top-1/2 transform -translate-y-1/2 left-12 text-light" onClick={prevImage}><ChevronLeftIcon fontSize="large" /></button>
                        <button className="absolute top-1/2 transform -translate-y-1/2 right-12 text-light" onClick={nextImage}><ChevronRightIcon fontSize="large" /></button>
                        <button className="absolute top-0 right-0 m-4 text-light" onClick={closeImage}><CloseIcon fontSize="large" /></button>
                    </div>
                )}
            </div>
        </div>
    );
}
