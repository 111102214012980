import { useEffect, useState } from "react";
import { projets } from "../projets/projets_content";
import Gallery from "../component/Gallery";
import {  getTextColor } from "../tools/getColor";
import ProjetAccueil from "../component/ProjetAccueil";
import Header from "../component/Header";
export default function Projet(props) {
    const { id, sendPageView } = props;
    const [projet, setProjet] = useState();


    useEffect(() => {
        const projet = projets.find((projet) => projet.id === id);
        setProjet(projet);
        sendPageView(`/projet/${id}`, `Projet ${projet.titre}`);
    }, [id])

    const textColor = getTextColor(id);

    return (
        <>
            <Header retracted={false} />

            {projet ?
                <>
                <ProjetAccueil projet={projet} />
                    
                    <div className="w-5/6 mx-auto mt-12">
                            <h2 className={`text-3xl md:text-4xl lg:text-5xl titre font-bold mb-12 ${textColor}`}>Images du projet.</h2>
                            <Gallery images={projet.images} />

                            {projet.video &&
                                <div className="mt-12">
                                    <h2 className={`text-3xl md:text-4xl lg:text-5xl titre font-bold mb-12 ${textColor}`}>Vidéo du projet.</h2>
                                    <div className="relative h-0" style={{ paddingBottom: "56.25%" }}>
                                        <iframe
                                            className="absolute inset-0 w-full h-full"
                                            src={projet.video}
                                            title={projet.titre}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        />
                                    </div>
                                </div>
                            
                            }
                    </div>
                </>
                :
                <div>
                    projet introuvable
                </div>
            }
        </>


    )
}