import ListeProjets from "../projets/ListeProjets";
import Banner from "../component/Banner";
import camille from "../images/camille.jpeg";
import Header from "../component/Header";
import { useEffect } from "react";

export default function Accueil({sendPageView}){
    useEffect(() => {
        sendPageView("/accueil", "Accueil");
    }, [])

    return (
        <>
        <Header retracted={true} />

        <Banner image={{
            img: camille, 
            alt:"photo de camille untereiner"
        }} titre1="CAMILLE"
        titre2="UNTEREINER"
        textes={["Diplômée en décoration d’intérieur et étudiante en 3ème année de Bachelor en architecture d’intérieur, venez découvrir mon parcours ainsi que mon univers."]}
        color="bg-banner"
        />
        <div className="w-5/6 mx-auto mt-12">
            <ListeProjets />
        </div>
        </>
    )
}