import React from "react";
import { archiSection, designSection } from "./projets_content";
import DescriptionProjet from "../component/DescriptionProjet";

export default function ListeProjets() {
  return (
    <>
      <div className="md:h-screen">
        <h2 className="text-5xl py-2 font-bold titre h-auto mb-10 md:mb-0 md:h-[10vh]">
          Architecture & décoration
        </h2>
        <div className="flex flex-row flex-wrap">
          {archiSection.map((projet, key) => (
            <div key={key} className="w-full md:w-1/2 mb-4">
              <DescriptionProjet projet={projet} gauche={key % 2 == 0} />
            </div>
          ))}
        </div>
      </div>

      <div className="md:h-screen mt-10">
        <h2 className="text-5xl py-2 font-bold titre h-auto mb-10 md:mb-0 md:h-[10vh]">Design</h2>
        <div className="flex flex-row flex-wrap">
          {designSection.map((projet, key) => (
            <div key={key} className="w-full md:w-1/2 mb-4">
              <DescriptionProjet projet={projet} gauche={key % 2 == 0} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
