import { archiSection } from "../projets/projets_content";
import { designSection } from "../projets/projets_content";
import { getProjetHref } from "../tools/getProjetHref";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import cvFile from "../downloads/CV_Camille_UNTEREINER.pdf";
import DownloadIcon from "@mui/icons-material/Download";
export default function Footer() {
  return (
    <footer className="bg-black opacity-80 py-4">
      <div className="flex flex-col px-4 md:px-0 md:flex-row justify-around w-fit mx-auto md:w-full">
        <div className="text-light mb-4 md:mb-0">
          <h2 className="titre text-xl font-bold mb-1">
            Architecture & décoration
          </h2>
          <div className="flex flex-col">
            {archiSection.map((projet, key) => (
              <a href={getProjetHref(projet.id)} className="text-sm mb-1" key={key}>
                {projet.titre} - {projet.titre2}
              </a>
            ))}
          </div>
        </div>

        <div className="text-light mb-4 md:mb-0">
          <h2 className="titre text-xl font-bold mb-1">Design</h2>
          <div className="flex flex-col">
            {designSection.map((projet, key) => (
              <a href={getProjetHref(projet.id)} className="text-sm mb-1" key={key}>
                {projet.titre}
              </a>
            ))}
          </div>
        </div>

        <div className="text-light mb-4 md:mb-0">
          <h2 className="titre text-xl font-bold mb-1">Autres</h2>
          <div className="flex flex-col">
            <a href="/apropos" className="text-sm mb-1">
              A propos
            </a>
            <a href="/mentions" className="text-sm">
              Mentions légales
            </a>
          </div>
        </div>

        <div className="text-light mb-4 md:mb-0">
          <h2 className="titre text-xl font-bold mb-1">Contact</h2>
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-start mb-1">
              <PhoneIcon className="mr-1" fontSize="small" />
              <p className="text-sm">06.37.28.89.33</p>
            </div>
            <div className="flex flex-row items-center justify-start mb-1">
              <EmailIcon className="mr-1" fontSize="small" />
              <a
                href="mailto:camille.untereiner@gmail.com"
                className="hover:underline text-sm"
              >
                camille.untereiner@gmail.com
              </a>
            </div>
            <a
                  href={cvFile}
                  className={`text-sm`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                                    <DownloadIcon fontSize="small" />

                  Téléchargez mon CV
                </a>
          </div>
        </div>
      </div>

      <div className="h-px bg-light w-5/6 mx-auto mt-8"></div>
      <div className="text-center text-sm font-medium text-light mt-8">
        <p className="mb-2">
          © 2024 Portfolio Camille UNTEREINER. Tous droits réservés.
        </p>
        <p>Site réalisé par Adrien SCHER.</p>
      </div>
    </footer>
  );
}
