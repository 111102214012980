import { getTextColor } from "../tools/getColor";
import DownloadIcon from "@mui/icons-material/Download";
export default function ProjetAccueil({ projet }) {
  const textColor = getTextColor(projet.id);

  return (
    <div className="flex flex-col lg:flex-row items-center lg:justify-between min-h-screen">
      <div className="w-full lg:w-1/2 p-2">
        <div className="pt-8 sm:pt-16 pb-4 lg:pb-8 px-4">
          <h2
            className={`text-5xl md:text-6xl lg:text-7xl font-bold text-center titre ${textColor}`}
          >
            {projet.titre}
          </h2>
          <h2
            className={`text-3xl md:text-4xl lg:text-5xl font-bold text-center titre ${textColor}`}
          >
            {projet.titre2}
          </h2>
        </div>

        <div className="w-5/6 md:w-4/6 mx-auto">
          {projet.description.map((section, index) => (
            <p key={index} className={`pb-2 text-justify ${textColor}`}>
              {section}
            </p>
          ))}

          {projet.downloadFile && (
            <div className="mt-4">
            <a
              href={projet.downloadFile}
              className={`text-center font-bold ${textColor}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Le projet complet
              <DownloadIcon />
            </a>
            </div>
          )}
        </div>
      </div>
      <div className="w-full lg:w-1/2 h-96 md:h-screen overflow-hidden relative">
        <img
          className={`w-full h-full  object-cover`}
          src={projet.imagePrincipale.img}
          alt={projet.imagePrincipale.alt}
        />
      </div>
    </div>
  );
}
