import Accueil from "../pages/Accueil";
import Footer from "../component/Footer";
import { useParams } from "react-router";
import Projet from "./Projet";
import CookieConsent from "../component/CookieConsent";
import { useEffect } from "react";

export default function MainPage(props) {
  const { id } = useParams();
  const {sendPageView, accepted, handleAccept} = props;

  return (
    <div className="bg-light">
      
        {!accepted && <CookieConsent setAccepted={handleAccept} />}
      

      {id ? <Projet id={id} sendPageView={sendPageView} /> : <Accueil sendPageView={sendPageView} />}

      <div className="mt-12">
      <Footer />
      </div>
    </div>
  );
}
