import React, { useState } from "react";
import { archiSection, designSection } from "../projets/projets_content";
import { getProjetHref } from "../tools/getProjetHref";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

const ProjetsDropdown = ({ projets }) => {
  return (
    <div className="z-50 w-full sm:w-fit mx-auto bg-light texte font-medium py-4 px-4 sm:px-12">
      <div>
        <h2 className="font-bold titre">Architecture & décoration</h2>
        <div className="flex flex-col">
          {projets.archi.map((projet, key) => (
            <div key={key} className="flex flex-row items-center">
              <div className="bg-banner h-2 w-2 rounded-full mx-2"></div>
              <a
                className="text-base"
                key={key}
                href={getProjetHref(projet.id)}
              >
                {projet.titre} - {projet.titre2}
              </a>
            </div>
          ))}
        </div>
      </div>

      <div>
        <h2 className="font-bold titre">Design</h2>
        <div className="flex flex-col">
          {projets.design.map((projet, key) => (
            <div key={key} className="flex flex-row items-center">
              <div className="bg-banner h-2 w-2 rounded-full mx-2"></div>
              <a
                className="text-base"
                key={key}
                href={getProjetHref(projet.id)}
              >
                {projet.titre}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default function Header({ retracted }) {
  const animationDuration = 250;
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuProjetsOpen, setMenuProjetsOpen] = useState(false);
  const [hiddenMode, setHiddenMode] = useState(retracted);

  const [headerInfoLeftToRight, setHeaderInfoLeftToRight] = useState(false);
  const [headerInfoRightToLeft, setHeaderInfoRightToLeft] = useState(false);

  const [menuTopToBot, setMenuTopToBot] = useState(false);
  const [menuBotToTop, setMenuBotToTop] = useState(false);

  const [projetsLeftToRight, setProjetsLeftToRight] = useState(false);
  const [projetsRightToLeft, setProjetsRightToLeft] = useState(false);

  const toggleMenuProjets = () => {
    const value = !menuProjetsOpen;
    if (value) {
        console.log("menu projet ouvert");
      setProjetsLeftToRight(true);
      setProjetsRightToLeft(false);
      setMenuProjetsOpen(value);
    } else {
      setProjetsLeftToRight(false);
      setProjetsRightToLeft(true);
      setTimeout(() => {
        setMenuProjetsOpen(value);
      }, animationDuration);
    }
  };

  const toggleMenu = () => {
    const value = !menuOpen;
    if (value) {
      setMenuBotToTop(false);
      setMenuTopToBot(true);
      setMenuOpen(value);
    } else {
      setMenuBotToTop(true);
      setMenuTopToBot(false);
      setTimeout(() => {
        setMenuOpen(value);
      }, animationDuration);
    }
    setMenuProjetsOpen(false);
  };

  const toggleHiddenMode = () => {
    const value = !hiddenMode;
    setMenuProjetsOpen(false);
    if (value) {
      setHeaderInfoLeftToRight(false);
      setHeaderInfoRightToLeft(true);
      setTimeout(() => {
        setHiddenMode(value);
      }, animationDuration);
    } else {
      setHeaderInfoRightToLeft(false);
      setHeaderInfoLeftToRight(true);
      setHiddenMode(value);
    }
  };

  return (
    <header className={`w-full text-xl font-extrabold texte`}>
      <button
        className="sm:hidden fixed top-0 left-0 z-40"
        onClick={toggleMenu}
      >
        <MenuIcon />
      </button>

      {hiddenMode && (
        <button
          onClick={toggleHiddenMode}
          className="left-0 top-0 z-40 fixed h-16 sm:block hidden"
        >
          <ArrowRightIcon fontSize="large" />
        </button>
      )}

      {menuProjetsOpen && (
        <div
          className={`w-full h-auto fixed sm:top-16 top-40 z-50 ${
            projetsLeftToRight && "left_to_right"
          } ${projetsRightToLeft && "right_to_left"}`}
        >
          <ProjetsDropdown
            projets={{ archi: archiSection, design: designSection }}
          />
        </div>
      )}

      {menuOpen && (
        <>
          <div
            className={`sm:hidden flex flex-col z-40 fixed top-0 bg-light w-full texte p-4 h-40 ${
              menuBotToTop && "bot_to_top"
            } ${menuTopToBot && "top_to_bot"}`}
          >
            <button onClick={toggleMenu} className="mb-2 text-start">
              <CloseIcon />
            </button>
            <a href="/" className="mb-2 titre">
              Accueil
            </a>
            <a href="/apropos" className="mb-2 titre">
              A Propos
            </a>
            <button
              onClick={toggleMenuProjets}
              className="mb-2 text-start titre"
            >
              Mes Projets
            </button>
          </div>
        </>
      )}

      {!hiddenMode && (
        <>
          <div
            className={`sm:block hidden fixed bg-light h-16 w-full z-40 top-0 p-2  ${
              headerInfoLeftToRight && "left_to_right"
            } ${headerInfoRightToLeft && "right_to_left"}`}
          >
            <div className="flex h-full w-full flex-row justify-around items-center bg-light ">
              <div>
                <a href="/" className="mx-14 titre">
                  Accueil
                </a>
                <a href="/apropos" className="titre">
                  A Propos
                </a>
                <button onClick={toggleMenuProjets} className="mx-14 titre">
                  Mes Projets
                </button>

                <button className="fixed right-0" onClick={toggleHiddenMode}>
                  <ArrowLeftIcon fontSize="large" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </header>
  );
}
