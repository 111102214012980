import fable1 from "../images/fables/1.jpg";
import fable3 from "../images/fables/3.png";
import fable4 from "../images/fables/4.png";
import fable5 from "../images/fables/5.png";
import fable6 from "../images/fables/6.jpg";
import fable7 from "../images/fables/7.jpg";
import fable8 from "../images/fables/8.png";
import fable9 from "../images/fables/9.jpg";
import fable10 from "../images/fables/10.png";
import fable11 from "../images/fables/11.png";
import fable12 from "../images/fables/12.jpg";
import fable13 from "../images/fables/13.png";
import fable14 from "../images/fables/14.png";
import fable15 from "../images/fables/15.png";
import fable16 from "../images/fables/16.png";
import fable17 from "../images/fables/17.jpg";
import fable18 from "../images/fables/18.png";
import fable19 from "../images/fables/19.png";
import fable20 from "../images/fables/20.png";
import fable21 from "../images/fables/21.png";
import fable22 from "../images/fables/22.png";
import planFable1 from "../images/fables/plan_1.jpg";
import planFable2 from "../images/fables/plan_2.jpg";
import planFable3 from "../images/fables/plan_3.jpg";
import planFable4 from "../images/fables/plan_4.jpg";

import fableDownload from "../downloads/Villa_carola.pdf";



import racine from "../images/racines/racines.png";
import racine2 from "../images/racines/racines_2.jpg";
import racine3 from "../images/racines/racines_3.jpg";
import racine4 from "../images/racines/racines_4.png";
import videoRacine from "../images/racines/video.mp4";

import oecophilla from "../images/oecophilla/oecophilla.png";
import oecophilla2 from "../images/oecophilla/oecophilla_2.png";
import oecophilla3 from "../images/oecophilla/oecophilla_3.png";
import oecophilla4 from "../images/oecophilla/oecophilla_4.png";
import oecophilla5 from "../images/oecophilla/oecophilla_5.png";

import plume1 from "../images/plume/plume_1.png";
import plume2 from "../images/plume/plume_2.png";
import plume3 from "../images/plume/plume_3.png";
import plume4 from "../images/plume/plume_4.png";
import plume5 from "../images/plume/plume_5.png";
import plume6 from "../images/plume/plume_6.png";
import plume7 from "../images/plume/plume_7.png";
import plume8 from "../images/plume/plume_8.png";
import plume9 from "../images/plume/plume_9.png";
import plume10 from "../images/plume/plume_10.png";
import plume11 from "../images/plume/plume_11.png";
import plume12 from "../images/plume/plume_12.png";
import plume13 from "../images/plume/plume_13.png";
import plume14 from "../images/plume/plume_14.png";
import plume15 from "../images/plume/plume_15.png";
import plume16 from "../images/plume/plume_16.png";
import plume17 from "../images/plume/plume_17.png";
import plume18 from "../images/plume/plume_18.png";
import plume19 from "../images/plume/plume_19.png";
import plume20 from "../images/plume/plume_20.png";
import plume21 from "../images/plume/plume_21.png";
import plume22 from "../images/plume/plume_22.png";
import plume23 from "../images/plume/plume_23.png";
import plume24 from "../images/plume/plume_24.png";
import plume25 from "../images/plume/plume_25.png";
import plume26 from "../images/plume/plume_26.png";
import plume27 from "../images/plume/plume_27.png";
import plume28 from "../images/plume/plume_28.png";
import plume29 from "../images/plume/plume_29.png"; 
import plume30 from "../images/plume/plume_30.png"; 
import plume31 from "../images/plume/plume_31.png";
import plume32 from "../images/plume/plume_32.png";
import plume33 from "../images/plume/plume_33.png";
import plume34 from "../images/plume/plume_34.png";
import plumeDownload from "../downloads/La_plume_du_temps.pdf";
import planPlume1 from "../images/plume/plan_1.jpg";
import planPlume2 from "../images/plume/plan_2.jpg";
import planPlume3 from "../images/plume/plan_3.jpg";
import planPlume4 from "../images/plume/plan_4.jpg";
import planPlume5 from "../images/plume/plan_5.jpg";
import planPlume6 from "../images/plume/plan_6.jpg";
import planPlume7 from "../images/plume/plan_7.jpg";
import planPlume8 from "../images/plume/plan_8.jpg";
import planPlume9 from "../images/plume/plan_9.jpg";
import planPlume10 from "../images/plume/plan_10.jpg";


export const archiSection = [
  {
    id: "plume",
    titre: "La Plume du Temps",
    titre2: "Atelier d'artisans",
    description: "Réhabilitation d’une maison et d’un Hangar situés à Ingwiller en habitation, atelier et Espace public pour un artisan horloger et un artisan plumassière.",
    imagePrincipale: {
      img: plume33,
      alt: "Photo principale du projet plume",
    },
  },
  {
    id: "fables",
    titre: "Les Fables",
    titre2: "Chambres d’hôtes",
    description: `Réhabilitation de la Villa Carola de Ribeauvillé en Chambre d’hôtes, le tout sur le thème des Fables de la Fontaine.`,
    imagePrincipale: {
      img: fable17,
      alt: "Photo principale du projet fables",
    },
  },
];

export const designSection = [
  {
    id: "oecophylla",
    titre: "Oecophylla",
    description: `Cette assise est inspirée par la Fourmi tisserande. Un «Fauteuil-Cocon» pour venir se détendre.`,
    imagePrincipale: {
      img: oecophilla,
      alt: "Photo principale du projet oecophylla",
    },
  },
  {
    id: "racines",
    titre: "Racines",
    description: `Mobilier urbain destiné à un lieu existant. Apporter des assises qui s’intègrent dans cet espace sans le dénaturer.`,
    imagePrincipale: {
      img: racine,
      alt: "Image principale du projet racines",
    },
  },
];









export const projets = [
  {
    id: "plume",
    titre: "La Plume du Temps",
    titre2: "Atelier d'artisans",
    description:
      [
        "La plume du Temps est un projet que j’ai effectué lors de ma 3ème année de Bachelor en architecture d’intérieur.",

        `Pour ce projet, deux bâtiments mitoyens à Ingwiller. Le premier étant une ancienne habitation composée
      d’un second bâtiment qui servait de hangar. La demande est de proposer différents espaces et utilité à ces deux
      bâtiments, de pouvoir les mettre en valeur et de permettre à un couple d’artisans horloger et plumassier de
      pouvoir l’habiter, y travailler et présenter leur travail.`,

        `
      J’ai décidé de mixer des inspirations liées à la plumasserie et à l’horlogerie. Le travail de précision et de finesse
        rassemble ces deux métiers.
        Mes inspirations esthétiques principales sont l’art déco couplé à une architecture et décoration japonisante
        comme le travail de Shigeru Ban par exemple.
      `,

        `Vous trouverez donc dans ce projet, un espace habitation, un atelier pour chaque artisan, un espace public
      avec Librairie, Workshop, Salle polyvalente, Cantine, Exposition intérieure & extérieure ainsi qu’un logement
      de location pour les invités.`,
      ],
    imagePrincipale: {
      img: plume33,
      alt: "photo principale du projet plume",
    },
    downloadFile: plumeDownload,
    images: [
      {
        img: planPlume1,
        alt: "Plan du projet plume",
      }
      ,
      {
        img: planPlume2,
        alt: "Plan du projet plume",
      }
      ,
      {
        img: planPlume3,
        alt: "Plan du projet plume",
      }
      ,
      {
        img: planPlume4,
        alt: "Plan du projet plume",
      }
      ,
      {
        img: planPlume5,
        alt: "Plan du projet plume",
      }
      ,
      {
        img: planPlume6,
        alt: "Plan du projet plume",
      }
      ,
      {
        img: planPlume7,
        alt: "Plan du projet plume",
      }
      ,
      {
        img: planPlume8,
        alt: "Plan du projet plume",
      }
      ,
      {
        img: planPlume9,
        alt: "Plan du projet plume",
      }
      ,
      {
        img: planPlume10,
        alt: "Plan du projet plume",
      }
      ,
      {
        img: plume1,
        alt: "photo du projet plume",
      },
      {
        img: plume2,
        alt: "photo du projet plume",
      },
      {
        img: plume3,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume4,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume5,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume6,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume7,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume8,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume9,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume10,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume11,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume12,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume13,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume14,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume15,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume16,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume17,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume18,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume19,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume20,
        alt: "photo du projet plume",
      }

      ,
      {
        img: plume21,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume22,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume23,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume24,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume25,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume26,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume27,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume28,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume29,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume30,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume31,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume32,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume33,
        alt: "photo du projet plume",
      }
      ,
      {
        img: plume34,
        alt: "photo du projet plume",
      }
      
    ],
  },
  {
    id: "fables",
    titre: "Les Fables",
    titre2: "Chambres d'Hôtes",
    downloadFile: fableDownload,
    description: [
      "Découvrez « Les fables - Chambres d’hôtes », qui est mon projet final d’examen de décoration d’intérieur que j’ai effectué durant ma formation en reconversion en 1 année.",

      `
      Le projet consistait à réhabiliter la Villa Carola de Ribeauvillé en chambre d’hôte. J’ai décidé de m’inspirer des 
      fables de la fontaine pour imaginer mes différents espaces, comme si la villa se transformait en recueil d’histoire.
      Chaque espace devenant alors la narration d’une fable qui met en avant la faune locale.
      `,

      `
      Vous trouverez dans mon projet, un hall d’entrée qui agit comme un sommaire, un restaurant et un bar haut
      en couleur, une suite familiale, une chambre romantique et pour finir un espace extérieur paysager invitant à
      la promenade.
      `,

    ],
    imagePrincipale: {
      img: fable17,
      alt: "Photo principale du projet fables",
    },
    images: [
      {
        img: fable1,
        alt: "Photo du projet fables",
      },
      {
        img: planFable1,
        alt: "Plan du projet fables",
      }
      ,
      {
        img: planFable2,
        alt: "Plan du projet fables",
      }
      ,
      {
        img: planFable3,
        alt: "Plan du projet fables",
      },
      {
        img: planFable4,
        alt: "Plan du projet fables",
      },
      {
        img: fable3,
        alt: "Photo du projet fables",
      },
      {
        img: fable4,
        alt: "Photo du projet fables",
      },
      {
        img: fable5,
        alt: "Photo du projet fables",
      },
      {
        img: fable6,
        alt: "Photo du projet fables",
      },
      {
        img: fable7,
        alt: "Photo du projet fables",
      },
      {
        img: fable8,
        alt: "Photo du projet fables",
      },
      {
        img: fable9,
        alt: "Photo du projet fables",
      },
      {
        img: fable10,
        alt: "Photo du projet fables",
      },
      {
        img: fable11,
        alt: "Photo du projet fables",
      },
      {
        img: fable12,
        alt: "Photo du projet fables",
      },
      {
        img: fable13,
        alt: "Photo du projet fables",
      },
      {
        img: fable14,
        alt: "Photo du projet fables",
      },
      {
        img: fable15,
        alt: "Photo du projet fables",
      },
      {
        img: fable16,
        alt: "Photo du projet fables",
      },
      {
        img: fable17,
        alt: "Photo du projet fables",
      },
      {
        img: fable18,
        alt: "Photo du projet fables",
      },
      {
        img: fable19,
        alt: "Photo du projet fables",
      },
      {
        img: fable20,
        alt: "Photo du projet fables",
      },
      {
        img: fable21,
        alt: "Photo du projet fables",
      },
      {
        img: fable22,
        alt: "Photo du projet fables",
      },
    ],
    video: videoRacine, 
  },
  {
    id: "oecophylla",
    titre: "Oecophylla",
    description: [
      `Oecophila s’inspire de la fourmi tisserande qui courbe les feuilles et les assemble grâce au fil de soie pour
      créer son nid. Ce Mobilier est un cocon à disposer dans un espace public pour pouvoir s’installer seul ou à
      plusieurs et être dans une zone plus calme et intime. Un endroit où se ressourcer et se détendre à l’abri de la
      foule.`,

      "Structure : Fibre de lin, Acier laqué vert sapin, Aimants",
      "Tissus : Satin de soie vert sapin, Velours maille perle côtelé vert",
      "Remplissage : Mousse densité 40 kg/m3 haute résilience"
    ],
    imagePrincipale: {
      img: oecophilla,
      alt: "Photo principale du projet oecophylla",
    },
    images: [
      {
        img: oecophilla,
        alt: "Photo principale du projet oecophylla",
      },
      {
        img: oecophilla2,
        alt: "Photo du projet oecophylla",
      },
      {
        img: oecophilla3,
        alt: "Photo du projet oecophylla",
      },
      {
        img: oecophilla4,
        alt: "Photo du projet oecophylla",
      },
      {
        img: oecophilla5,
        alt: "Photo du projet oecophylla",
      },
    ],
  },
  {
    id: "racines",
    titre: "Racines",
    description: [
      `
      Le projet Racines est une implantation d’un mobilier urbain dans un espace déjà existant, venant se greffer
      subtilement au paysage sans dénaturer le lieu. Le but étant de créer une utilité à cet objet qui manque dans le lieu actuel.
      `,
      
      "Racines a envahi le lieu pour y apporter des assises comme si la nature reprenait ses droits dans cet environnement urbain.",
      "Inspiré du travail de Frank Gehry et des Frères Bouroulec.",
    ],
    imagePrincipale: {
      img: racine,
      alt: "Image principale du projet racines",
    },
    images: [
      {
        img: racine,
        alt: "Image principale du projet racines",
      },
      {
        img: racine2,
        alt: "Image du projet racines",
      },
      {
        img: racine3,
        alt: "Image du projet racines",
      },
      {
        img: racine4,
        alt: "Image du projet racines",
      },
    ],
  },
];
