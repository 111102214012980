import { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
export default function Banner(props) {
  const { image, titre1, titre2, textes, color, imageRight, cvFile } = props;
  const [imageAnimation, setImageAnimation] = useState(false);
  const [textAnimation, setTextAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTextAnimation(true);
    }, 500);
    setTimeout(() => {
      setImageAnimation(true);
    }, 1000);
  }, []);
  return (
    <div className={`min-h-screen flex flex-col md:flex-row ${color} ${!imageRight && 'h-screen'}`}>
      {!imageRight && (
        <div className="flex-1 md:w-1/2 md:pt-0 pt-10 flex-1 md:h-full h-2/3 overflow-hidden relative">
          <img
            className={`w-full h-full object-cover ${
              imageAnimation && "left_to_right"
            } ${!imageAnimation && "opacity-0"}`}
            src={image.img}
            alt={image.alt}
          />
        </div>
      )}

      <div
        className={`w-full flex flex-1 md:w-1/2 flex justify-center items-center my-auto ${
          imageRight && "mt-0 sm:mt-16"
        }`}
      >
        <div
          className={`text-center h-full p-8 ${
            textAnimation && !imageRight && "left_to_right"
          } ${!textAnimation && !imageRight && "opacity-0"}`}
        >
          <h2 className="text-5xl md:text-6xl lg:text-7xl font-bold text-light titre">
            {titre1}
          </h2>
          <h2 className="text-5xl md:text-6xl lg:text-7xl font-bold text-light titre">
            {titre2}
          </h2>

          <div className="mt-4 text-light text-base text-justify w-full sm:w-1/2 md:w-2/3 mx-auto">
            {textes.map((text, key) => (
              <p key={key} className="mt-3">
                {text}
              </p>
            ))}
          </div>

          <div className="mt-4 text-light text-base text-start w-full sm:w-1/2 md:w-2/3 mx-auto my-auto">
            {cvFile ? (
              <>
                <a
                  href={cvFile}
                  className={`pt-12 text-center font-bold`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Téléchargez mon CV
                  <DownloadIcon />
                </a>

                <div className="flex flex-row items-center justify-start mt-8">
                  <PhoneIcon className="mr-1" />
                  <p className="">06.37.28.89.33</p>
                </div>

                <div className="flex flex-row items-center justify-start mt-2">
                  <EmailIcon className="mr-1" />
                  <a
                    href="mailto:camille.untereiner@gmail.com"
                    className="hover:underline"
                  >
                    camille.untereiner@gmail.com
                  </a>
                </div>
              </>
            ) : (
              <a className="hover:underline font-bold" href="/apropos">
                En savoir plus
              </a>
            )}
          </div>
        </div>
      </div>

      {imageRight && (
        <div className="flex-1 md:w-1/2 md:pt-0 pt-10 overflow-hidden relative">
          <img
            className={`w-full h-full object-cover`}
            src={image.img}
            alt={image.alt}
          />
        </div>
      )}
    </div>
  );
}
