import { useEffect } from "react";
import Header from "../component/Header";
import Banner from "../component/Banner";
import Footer from "../component/Footer";
import camille2 from "../images/camille_2.jpeg";
import cv from "../downloads/CV_Camille_UNTEREINER.pdf";
const textes=[
    "Après une première période professionnelle en tant que chef pâtissière et cake design, j’ai décidé de changer ma vie et de réaliser un projet qui me tenait particulièrement à cœur: retourner à l’école pour devenir décoratrice et architecte d’intérieur.",
    "Début 2022, je me suis formée à distance, en parallèle de mon emploi, pour apprendre les bases du métier d’architecte d’intérieur, ce qui m’a conforté dans mon choix de reconversion.",
    "La même année, j’ai obtenu un financement, auprès de transi pro, pour pouvoir me former au métier de décoratrice d’intérieur ( 2 années en 1 ) à l’école MJM Graphic Design de Strasbourg.",
    "C’est alors, en 2023, que j’ai obtenu mon diplôme, avec mention Très Bien, grâce à mon projet « Les Fables - Chambres d’hôtes ». J’ai eu la chance de pouvoir poursuivre mes études avec une seconde année en Bachelor en architecture d’intérieur en alternance. Cette année supplémentaire m’a permis de me perfectionner et de compléter mes compétences. Pour encore plus de connaissances, en route pour 2 années supplémentaires en Mastère.",
    "Je suis passionnée et enjouée à l’idée de pouvoir créer au quotidien. Je suis fière de pouvoir vous présenter, aujourd’hui, le travail que j’ai effectué sur ces deux années d’études.", 
]

export default function Apropos({sendPageView}){
    useEffect(() => {
        sendPageView("/apropos", "A propos");
    })
    return (
        <>
        <Header retracted/>
        <Banner 
        image={{
            img: camille2, 
            alt:"photo de camille untereiner"
        }}
        titre1="A PROPOS"
        textes={textes}
        color="bg-banner"
        imageRight={true}
        cvFile={cv}
        />
                <Footer/>
                </>

    )
}