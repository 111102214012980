import React, { useState } from "react";

export default function CookieConsent({ setAccepted }) {
  const [open, setOpen] = useState(true);
  const handleAccept = () => {
    setAccepted(true);
    setOpen(false);
  };

  return (
    <>
      {open && (
        <div className="fixed bottom-0 z-50 flex flex-col items-center justify-center p-4 bg-light w-full">
        <h2 className="text-3xl titre font-bold mb-2">Consentement aux cookies</h2>
        <p className="text-sm mb-4">
          Ce site utilise des cookies pour améliorer votre expérience de navigation.
          En continuant à naviguer sur ce site, vous acceptez l'utilisation de cookies.
        </p>
        <button
          onClick={handleAccept}
          className=" font-bold"
        >
          Accepter
        </button>
      </div>
      )}
    </>
  );
}
